"use strict";

import Swiper from 'swiper';
import {Navigation, Pagination, Scrollbar} from 'swiper/modules';

document.addEventListener("DOMContentLoaded", function (event) {
    latestUpdatesSlider();
    heroBattleLeaderboardSlider();
});

function latestUpdatesSlider() {
    const latestUpdatesSlider = new Swiper('#latestUpdates .swiper', {
        modules: [Navigation, Pagination],
        slidesPerView: 'auto',
        initialSlide: getInitialSlide(),
        spaceBetween: 16,
        loop: false,
        centeredSlides: true,
        breakpoints: {
            640: {
                slidesPerView: 3,
                centeredSlides: false,
            },
        },
    });

    function getInitialSlide() {
        return window.innerWidth >= 640 ? 0 : 1;
    }

    window.addEventListener('resize', () => {
        const newInitialSlide = getInitialSlide();
        if (latestUpdatesSlider.activeIndex !== newInitialSlide) {
            latestUpdatesSlider.slideTo(newInitialSlide);
        }
    });
}

function heroBattleLeaderboardSlider() {
    new Swiper('#heroBattleLeaderboard .swiper', {
        modules: [Navigation, Pagination, Scrollbar],
        slidesPerView: 4,
        direction: "vertical",
        autoHeight: true,
        spaceBetween: 4,
        scrollbar: {
            el: "#heroBattleLeaderboard .swiper-scrollbar",
        },
    });
}
